<template>
  <div class="md:mt-8 ds-wrapper">
    <div class="custom-quillEditor quill-editor">
      <h4 class="font-medium text-xl md:text-3xl text-dark-blue mb-4 md:mb-6">{{ documentName }}</h4>
      <vx-card v-if="type == 'PRODUCT-LEGAL'" class="terms-card">
        <div id="fullTerms" v-html="currentLegal.explicitTerms" class="text-base terms-view-content ql-editor"></div>
        <vs-divider class="terms-divider" v-if="currentLegal.explicitTerms"></vs-divider>
        <div v-html="currentLegal.fullDocument" id="fullTerms" class="text-base terms-view-content ql-editor"></div>
      </vx-card>
      <vx-card v-else-if="type === 'GENERAL-WEBSITE-TERMS'" class="terms-card">
        <div id="fullTerms" v-html="latestWebsiteTerms.explicitTerms" class="text-base terms-view-content ql-editor"></div>
        <vs-divider class="terms-divider" v-if="latestWebsiteTerms.explicitTerms"></vs-divider>
        <div id="fullTerms" v-html="latestWebsiteTerms.fullDocument" class="text-base terms-view-content ql-editor"></div>
      </vx-card>
      <vx-card  v-else class="terms-card">
        <div id="fullTerms" v-html="latestPrivacy.fullDocument" class="text-base terms-view-content ql-editor"></div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import QuillEditorPreview from "@/views/QuillEditorPreview";
export default {
  name: "ProductLegalsView",
  components: {
    QuillEditorPreview,
  },
  data() {
    return {
      type: "",
      currentLegal: {},
      fullDocument: true,
      latestPrivacy: {},
      latestWebsiteTerms: {},
    };
  },
  methods: {
    ...mapActions("legals", ["fetchAllLegals", "fetchProductLegalsById"]),
    getTerms(type = "CARD") {
      this.fetchAllLegals(type)
        .then((result) => {
          this.$vs.loading.close();
          this.latestPrivacy = result.data.data.privacy;
          this.latestWebsiteTerms = result.data.data.websiteTerms;

          this.latestWebsiteTerms.fullDocument = this.replaceLegalContent(
              this.latestWebsiteTerms.fullDocument,
              {
                fullTerms: `<a href="#fullTerms">${this.latestWebsiteTerms.documentName}</a>`,
                privacy: `<a href="${this.latestPrivacy._id}?type=PRIVACY-POLICY&fullDocument=true" target="_blank">${this.latestPrivacy.documentName}</a>`,
                generalWebsiteTerms: `<a href="${this.latestWebsiteTerms._id}?type=GENERAL-WEBSITE-TERMS&fullDocument=true" target="_blank">${this.latestWebsiteTerms.documentName}</a>`,
              }
          );

          this.latestWebsiteTerms.explicitTerms = this.replaceLegalContent(
              this.latestWebsiteTerms.explicitTerms,
              {
                fullTerms: `<a href="#fullTerms">${this.latestWebsiteTerms.documentName}</a>`,
                privacy: `<a href="${this.latestPrivacy._id}?type=PRIVACY-POLICY&fullDocument=true" target="_blank">${this.latestPrivacy.documentName}</a>`,
                generalWebsiteTerms: `<a href="${this.latestWebsiteTerms._id}?type=GENERAL-WEBSITE-TERMS&fullDocument=true" target="_blank">${this.latestWebsiteTerms.documentName}</a>`,
              }
          );

          this.latestPrivacy.fullDocument = this.replaceLegalContent(
              this.latestPrivacy.fullDocument,
              {
                fullTerms: `<a href="#fullTerms">${this.latestPrivacy.documentName}</a>`,
                 privacy: `<a href="${this.latestPrivacy._id}?type=PRIVACY-POLICY&fullDocument=true" target="_blank">${this.latestPrivacy.documentName}</a>`,
                generalWebsiteTerms: `<a href="${this.latestWebsiteTerms._id}?type=GENERAL-WEBSITE-TERMS&fullDocument=true" target="_blank">${this.latestWebsiteTerms.documentName}</a>`,
              }
          );
        }).catch((ex) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Error fetching legals",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },

    getProductTerms(id) {
      this.$vs.loading();
      this.fetchProductLegalsById(id).then((result) => {
        this.$vs.loading.close();
        const { legals } = result.data.data.legals;
        const { privacy, websiteTerms } = result.data.data;

        if (this.$route.name == "borrower") {
          this.currentLegal = legals.filter((el) => el.user === "CUSTOMER");
          this.currentLegal = this.currentLegal[this.currentLegal.length - 1];
        } else {
          this.currentLegal = legals.find((el) => el._id === id);
        }

        this.currentLegal.explicitTerms = this.replaceLegalContent(
          this.currentLegal.explicitTerms,
          {
            fullTerms: `<a href="#fullTerms">${this.currentLegal.documentName}</a>`,
            privacy: `<a href="${privacy._id}?type=PRIVACY-POLICY&fullDocument=true" target="_blank">${privacy.documentName}</a>`,
            generalWebsiteTerms: `<a href="${websiteTerms._id}?type=GENERAL-WEBSITE-TERMS&fullDocument=true" target="_blank">${websiteTerms.documentName}</a>`,
          }
        );

        this.currentLegal.fullDocument = this.replaceLegalContent(
          this.currentLegal.fullDocument,
          {
            fullTerms: `<a href="#fullTerms">${this.currentLegal.documentName}</a>`,
            privacy: `<a href="${privacy._id}?type=PRIVACY-POLICY&fullDocument=true" target="_blank">${privacy.documentName}</a>`,
            generalWebsiteTerms: `<a href="${websiteTerms._id}?type=GENERAL-WEBSITE-TERMS&fullDocument=true" target="_blank">${websiteTerms.documentName}</a>`,
          }
        );

      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: ex.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },
  },
  computed: {
    documentName() {
      if (this.type === "PRODUCT-LEGAL") {
        return this.currentLegal.documentName;
      } else if (this.type === "PRIVACY-POLICY") {
        return this.latestPrivacy.documentName;
      } else {
        return this.latestWebsiteTerms.documentName;
      }
    }
  },

  async beforeMount() {
    this.type = this.$route.query.type;
    let id = this.$route.params.id;

    if (this.$route.query.fullDocument) {
      this.fullDocument = JSON.parse(this.$route.query.fullDocument);
    }

    if (id.includes("PL") && this.$route.name == "borrower") {
      this.type = "PRODUCT-LEGAL";
      id = `${id}/customer`;
    }

    if (this.type == 'PRODUCT-LEGAL') {
      await this.getProductTerms(id);
    } else {
      this.getTerms();
    }
  },
};
</script>
